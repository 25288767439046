import React, { useContext, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com"; // Import emailjs-com
import "react-toastify/dist/ReactToastify.css";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";

const ContactForm = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
    const textColor = isDarkMode ? "text-white" : "text-gray-500";

    const form = useRef();
    const [formData, setFormData] = useState({
        user_name: "",
        user_email: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_bmblsfc",
                "template_ppnq07u",
                form.current,
                "V29YcDp9rNU1dmhVP" // Replace with your EmailJS User ID
            )
            .then(
                (result) => {
                    console.log(result.text);
                    toast.success("Email sent successfully");
                    // reset the form
                    setFormData({
                        user_name: "",
                        user_email: "",
                        subject: "",
                        message: "",
                    });
                },
                (error) => {
                    console.error(error.text);
                    toast.error("Failed to send email");
                }
            );
    };

    return (
        <section className="relative pb-8">
            <div className="flex flex-wrap md:px-4">
                <form
                    ref={form}
                    onSubmit={sendEmail}
                    className={`p-8 md:mx-4 ${isDarkMode ? "bg-gray-900" : "bg-white"} rounded-md shadow-md`}
                >
                    <div className="m-3">
                        <h3 className={`${boldColor} text-2xl font-medium mb-6`}>
                            Let's get in contact!
                        </h3>
                        <div className="text-gray-400 mb-3">
                            <p className="m-1">
                                I'm always open to conversations about job opportunities,
                                questions about my portfolio, or even casual conversations, so
                                let's get in contact!
                            </p>
                            <p className="m-1">
                                My email address is{" "}
                                <a
                                    className="text-blue-500 underline"
                                    href="mailto:usais@mail.gvsu.edu"
                                >
                                    usais@mail.gvsu.edu
                                </a>
                                , the contact form below is also another great way to contact me! Send me a message and I will reply to you soon!
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex flex-wrap pt-6">
                        <div className="w-full md:w-1/2">
                            <div className="m-3">
                                <p className={`pb-2 ${isDarkMode ? "text-white" : "text-black"}`}>
                                    Your Name
                                </p>
                                <input
                                    className={`w-full border rounded py-4 px-6 text-sm ${
                                        isDarkMode
                                            ? "bg-gray-800 border-gray-700 text-white"
                                            : "bg-gray-100 border-gray-50 text-black"
                                    }`}
                                    type="text"
                                    name="user_name"
                                    value={formData.user_name}
                                    onChange={handleChange}
                                    placeholder="Your Name"
                                    required
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-1/2">
                            <div className="m-3">
                                <p className={`pb-2 ${isDarkMode ? "text-white" : "text-black"}`}>
                                    Your Email
                                </p>
                                <input
                                    className={`w-full border rounded py-4 px-6 text-sm ${
                                        isDarkMode
                                            ? "bg-gray-800 border-gray-700 text-white"
                                            : "bg-gray-100 border-gray-50 text-black"
                                    }`}
                                    type="email"
                                    name="user_email"
                                    value={formData.user_email}
                                    onChange={handleChange}
                                    placeholder="email@mail.com"
                                    required
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="m-3">
                                <p className={`pb-2 ${isDarkMode ? "text-white" : "text-black"}`}>Subject</p>
                                <input
                                    className={`w-full border rounded py-4 px-6 text-sm ${
                                        isDarkMode
                                            ? "bg-gray-800 border-gray-700 text-white"
                                            : "bg-gray-100 border-gray-50 text-black"
                                    }`}
                                    type="text"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    placeholder="Let me know how I can help you!"
                                    required
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="m-3">
                                <p className={`pb-2 ${isDarkMode ? "text-white" : "text-black"}`}>Your Message</p>
                                <textarea
                                    className={`w-full border rounded py-4 px-6 text-sm ${
                                        isDarkMode
                                            ? "bg-gray-800 border-gray-700 text-white"
                                            : "bg-gray-100 border-gray-50 text-black"
                                    }`}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder="Leave a message for me to read"
                                    required
                                    rows="6"
                                />
                            </div>
                        </div>
                        <div className="lg:w-2/5 sm:w-full  sm:w-1/3">
                            <div className="m-3">
                                <input
                                    className=" sm:w-full inline-block mb-3 rounded-full bg-blue-500 hover:bg-blue-300 transition ease-out duration-200 text-center border-0 py-3 px-8 text-white leading-7 tracking-wide"
                                    type="submit"
                                    value="Send Message"
                                />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </form>
            </div>
        </section>
    );
};

export default ContactForm;
