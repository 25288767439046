import React from "react";
import KnowledgeItems from "./KnowledgeItems";

const knowledgeData = [

    {
        id: 1,
        title: "HTML, CSS, JavaScript",
    },
    {
        id: 2,
        title: "PHP | Phalcon",
    },
    {
        id: 3,
        title: "jQuery",
    },
    {
        id: 4,
        title: "Bootstrap | Tailwind",
    },
    {
        id: 5,
        title: "Node.js | Express.js",
    },
    {
        id: 6,
        title: "React.js | Next.js",
    },
    {
        id: 7,
        title: "MySQL | PostgreSQL",
    },
    {
        id: 8,
        title: "MongoDB",
    },
    {
        id: 9,
        title: "Lua | Lapis",
    },
    {
        id: 10,
        title: "Firebase",
    },
    {
        id: 11,
        title: "Python | Flask",
    },
    {
        id: 12,
        title: "COBOL",
    },
    {
        id: 13,
        title: "RESTful | GraphQL APIs",
    },
    {
        id: 14,
        title: "Git | GitHub",
    },
    {
        id: 15,
        title: "Adobe Photoshop",
    },
    {
        id: 16,
        title: "Sony Vegas Pro",
    },

    {
        id: 17,
        title: "Social Media Growth",
    },
    {
        id: 18,
        title: "Search Ranking Strategies",
    },
    {
        id: 19,
        title: "Content Creations",
    },
    {
        id: 20,
        title: "Personal Branding",
    },

];

const Knowledges = () => {
    return (
        <section className="pb-4">
            <div className="flex justify-center flex-wrap ">
                {knowledgeData.map((knowledge, id) => (
                    <KnowledgeItems knowledge={knowledge} key={id} />
                ))}
            </div>
        </section>
    );
};

export default Knowledges;