import React, { useContext } from "react";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";

const ExperienceItem = (props) => {
    const { isDarkMode } = useContext(ThemeContext);

    const cardBackground = isDarkMode ? "bg-gray-900" : "bg-white";
    const textColor = isDarkMode ? "text-white" : "text-gray-800";
    const textColor4 = isDarkMode ? "text-white" : "text-purple-500";

    const textColor2 = isDarkMode ? "text-gray-400" : "text-gray-600";
    const textColor3 = isDarkMode ? "text-white" : "text-blue-500";

    const { title, description, name, date, logo } = props.experience;

    return (
        <div className={`lg:mx-4 sm:mx-2 mb-8 ${isDarkMode ? "bg-gray-900" : "bg-white"} shadow rounded-md overflow-hidden`}>
            <div className="flex flex-row items-start p-6"> {/* p-6 will define the padding around the content */}
                {/* Company Logo/Image on the left */}
                <div className="flex-shrink-0 mr-4">
                    <img
                        src={logo}
                        alt={name}
                        className="w-16 h-16 object-contain"
                    />
                </div>
                {/* Text content */}
                <div className="flex-grow">
                    <div className="flex justify-between items-center mb-2">
                        <h3 className={`text-xl font-medium ${textColor}`}>{title}</h3>
                        <h3 className={`px-3 text-md font-medium ${textColor4}`}>{name}</h3>
                    </div>
                    <div className={`text-sm ${textColor3}`} dateTime={date}>
                        {date}
                    </div>
                    <p className={`text-sm ${textColor2}`}>{description}</p>
                </div>
            </div>
        </div>
    );
};

export default ExperienceItem;
