import React, { useContext } from "react";
import {ThemeContext} from "../../../../ThemeProvider/ThemeProvider";
import { FaExternalLinkAlt} from "react-icons/fa";


const EducationItem = (props) => {
    const { isDarkMode } = useContext(ThemeContext);

    const textColor = isDarkMode ? "text-white" : "text-gray-500"
    const textColor2 = isDarkMode ? "text-white" : "text-gray-600"

    const { schoolpic, link, button, title, description, name, date } = props.education;
    return (
        <div className="w-full lg:w-1/2 mb-8">
            <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} mb-6 md:mx-4 p-6 shadow rounded-md group hover:shadow-md overflow-hidden flex flex-col h-full`}>
                <div className={`${isDarkMode ? "bg-gray-800" : "bg-gray-50"} rounded-lg p-3 overflow-hidden`}>
                    <img alt="school images" className="lg:h-48 md:h-36 w-full object-contain object-center"
                         src={schoolpic}/>

                </div>
                <div className="mb-2">
                    <time className="text-gray-400 text-sm font-medium" dateTime={date}>{date}</time>
                    <h3 className={`${textColor} text-xl font-light`}>{name}</h3>
                </div>
                <div className="flex-grow">
                    <a className={`${textColor2} block text-lg font-medium hover:text-blue-300 mb-2 underline transition ease-out duration-200`}
                       target="_blank"
                       rel="noreferrer noopener"
                       href={link}
                       aria-label={`${title} - ${button}`}>
                        {title}
                    </a>
                    <p className={`text-sm text-gray-400 ${textColor}`}>{description}</p>
                </div>
                <a className="justify-center mx-2 mt-4 inline-flex items-center rounded-full bg-blue-500 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-blue-300 transition ease-out duration-200"
                   target="_blank"
                   rel="noreferrer noopener"
                   href={link}
                   aria-label={button}>
                    <FaExternalLinkAlt className="w-4 h-4 mr-2"/>
                    {button}
                </a>
            </div>
        </div>
    );
};

export default EducationItem;