import React, { useContext } from "react";
import Skills from "./Skills/Skills";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";

const About = () => {
  const { isDarkMode } = useContext(ThemeContext);

  const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
  const textColor = isDarkMode ? "text-gray-400" : "text-gray-500";

  return (
      <section className="relative py-8">
        <div className="flex flex-wrap md:px-4">
          <div className="w-full">
            <div className="md:mx-4">
              <h3 className={`text-2xl font-medium mb-4 ${boldColor}`}>
                About Me
              </h3>
              <div className="text-gray-400 mb-3 text-md">
                <p className="m-1">
                  I am a full stack web developer.
                  My background as a social media content creator has provided me valuable transferable skills such as, but not limited to, Digital Marketing, Search Engine Optimization, Click Funnels, and Brand Partnerships.
                </p>
                <p className="m-1">
                  Since 2015, I have owned multiple successful {" "}
                  <span className="text-red-500">
                                    YouTube {" "}
                                </span>
                  channels primarily on the Roblox.com platform with large followings over the years, demonstrating my skills in online content creation and audience engagement. In 2022, I decided to pursue my interest in web development and have been building web projects to showcase what I have been learning.
                </p>
              </div>
            </div>

            <section className="relative">
              <div className="flex flex-wrap md:px-4">
                <div className="w-full">
                  <h3 className={`text-2xl font-medium mb-4 ${boldColor}`}>
                    Cool Technologies I'm Using!
                  </h3>
                  <section className="pb-4">
                    <div
                        className={`${
                            isDarkMode ? "bg-gray-900" : "bg-white"
                        } p-2 w-full shadow rounded-md group overflow-hidden flex flex-col h-full`}
                    >
                      <div className="flex justify-center flex-wrap text-center">
                        {[
                          {name: "PlanetScale", url: "https://planetscale.com"},
                          {name: "KeystoneJS", url: "https://www.keystonejs.com"},
                          {name: "NestJS", url: "https://nestjs.com"},
                          {name: "Pegasystem", url: "https://www.pega.com"},
                          {name: "Webflow", url: "https://webflow.com"},
                          {name: "Roblox Studio", url: "https://developer.roblox.com/en-us/studio"},
                          {name: "Voicemaker.in", url: "https://voicemaker.in"}
                        ].map(
                            (tech, index) => (
                                <div key={index} className="w-1/4 m-2 sm:w-1/4 md:w-1/5 flex flex-col">
                                  <div
                                      className={`mb-2 md:mx-2 shadow rounded-md ${isDarkMode ? "bg-gray-800" : "bg-blue-100"} group`}>
                                    <div className={`inline-block w-full py-2 ${textColor} font-medium`}>
                                      <a href={tech.url} target="_blank" rel="noopener noreferrer"
                                         className="underline">{tech.name}</a>
                                    </div>
                                  </div>
                                </div>
                            )
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <Skills/>
          </div>
        </div>
      </section>
  )
      ;
};

export default About;