import React, {useContext} from "react";
import {ThemeContext} from "../../../../ThemeProvider/ThemeProvider";
import { FaExternalLinkAlt } from "react-icons/fa";

const GFXItem = (props) => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
    // work in progress, make better card or portfolio page
    const {toptitle, image, title, btnlink1, btntype1} = props.gfx;
    return (
        <div className="w-full lg:w-1/2 mb-8">
            <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} mb-6 md:mx-4 p-6 shadow rounded-md group hover:shadow-md overflow-hidden flex flex-col h-full`}>
                <p className={`${isDarkMode ? "text-white" : "text-gray-500"} font-medium text-lg mb-2`}>
                    {toptitle}
                </p>
                <div className="mb-2">
                    <img src={image} alt="coverImage"
                         className="w-full h-full object-cover object-center"/>
                </div>
                <div className="flex-grow">
                    <div className={`mb-2 ${boldColor} font-medium text-xl`}>
                        {title}
                    </div>
                </div>

                <div className="flex justify-center flex-wrap">
                    <a className="mx-2 mt-4 w-full inline-flex justify-center items-center rounded-full bg-blue-500 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-blue-300 transition ease-out duration-200"
                       target="_blank"
                       href={btnlink1}
                       rel="noreferrer">
                        <FaExternalLinkAlt className="w-4 h-4 mr-2" />
                        {btntype1}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default GFXItem;