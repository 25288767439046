import React, { useContext, useState, useEffect } from "react";
import {
    FaGithub,
    FaLinkedinIn,
    FaTwitter,
    FaDeviantart,
    FaDiscord,
    FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaStepBackward, FaStepForward
} from "react-icons/fa";
import { SiRoblox, SiYoutube, SiInstagram } from "react-icons/si";
import picture from "../../../images/unnamed.jpg";
import resume from "../../../Resume/Singharaj-Usai-Resume2024.pdf";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";


const socials = [
    {
        id: 1,
        icon: <FaGithub />,
        link: "https://github.com/singharaj-usai",
        bgColor: "bg-gray-700", // Specify the background color here
        hoverColor: "hover:bg-gray-500", // Specify the hover color here
    },
    {
        id: 2,
        icon: <FaLinkedinIn />,
        link: "https://www.linkedin.com/in/singharaj-usai",
        bgColor: "bg-blue-600", // Specify the background color here
        hoverColor: "hover:bg-blue-400", // Specify the hover color here
    },
    {
        id: 3,
        icon: <SiYoutube />,
        link: "https://www.youtube.com/@singharajusai",
        bgColor: "bg-red-500",
        hoverColor: "hover:bg-red-300",
    },
    {
        id: 3,
        icon: <SiInstagram />,
        link: "https://www.instagram.com/singharajusai",
        bgColor: "bg-pink-500", // Specify the background color here
        hoverColor: "hover:bg-pink-300", // Specify the hover color here
    },
    {
        id: 4,
        icon: <FaTwitter />,
        link: "https://twitter.com/OMrbobbilly",
        bgColor: "bg-blue-400", // Specify the background color here
        hoverColor: "hover:bg-blue-200", // Specify the hover color here
    },
    {
        id: 5,
        icon: <FaDeviantart />,
        link: "https://deviantart.com/realmrbobbilly",
        bgColor: "bg-green-500", // Specify the background color here
        hoverColor: "hover:bg-green-300", // Specify the hover color here
    },


    {
        id: 6,
        icon: <SiRoblox />,
        link: "https://www.roblox.com/users/446187905/profile",
        bgColor: "bg-gray-500", // Specify the background color here
        hoverColor: "hover:bg-gray-300", // Specify the hover color here
    },
];



const Card = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";
    const textColor = isDarkMode ? "text-white" : "text-gray-400"
    const iconColor = "text-white"; // Update iconColor to always be white

    return (
        <aside className={`sticky top-0 ${isDarkMode ? "bg-gray-900" : "bg-white"} md:mx-8 lg:mx-4 mb-8 p-6 shadow-md rounded-md -mt-40`}>
            <div className="w-24 h-24 rounded-3xl overflow-hidden mx-auto mb-5">
                <img src={picture} alt="pfp" className="w-full" />
            </div>
            <div className="text-center ">
                <h1 className={`text-3xl font-bold mb-1 ${boldColor}`}>Singharaj Usai</h1>
                <p className="text-sm text-gray-400 my-2">Full-stack Web Developer</p>
                <div className="flex justify-center flex-wrap">
                    <a
                        className={`m-2 inline-block rounded-full bg-blue-500 text-center border-0 py-2 px-6 leading-7 tracking-wide text-white ${isDarkMode ? "hover:bg-gray-700" : "hover:bg-blue-300"} transition ease-out duration-200`}
                        href={resume}
                        rel="noreferrer"
                        target="_blank"
                    >
                        View Resume PDF
                    </a>
                </div>
                <ul className="flex flex-wrap justify-center">
                    {socials.map((social, id) => (
                        <SocialIcon social={social} key={id} iconColor={iconColor} />
                    ))}
                </ul>
            </div>
            <div className="text-start pt-4">
                <h3 className={`text-md mb-2 uppercase font-medium ${boldColor}`}>Welcome</h3>
                <div className={`text-sm font-light leading-relaxed ${textColor}`}>
                    <p className="my-1">
                        Hello! My name is{" "}
                        <span className="text-yellow-500">Singharaj Usai</span>,
                        welcome to my personal website!
                    </p>
                    <p className="my-1">
                        Read about my background, view my {" "}
                        <span className="text-green-500">Portfolio</span> page where I showcase public projects, read my {" "}
                        <a className={` leading-7 tracking-wide text-blue-500 underline`} href={resume} rel="noreferrer" target="_blank">resume</a>
                        , find out ways to contact me, and read occasional blog posts.
                    </p>
                    <p className="my-1">

                    </p>
                </div>
            </div>

        </aside>
    );
};

const SocialIcon = (props) => {
    const {icon, link, bgColor, hoverColor} = props.social;
    return (
        <li className="m-2">
            <a
                title="sociallinks"
                target="_blank"
                rel="noopener noreferrer"
                href={link}
                className={`w-8 h-8 rounded-full text-white flex items-center justify-center ${bgColor} ${hoverColor} transition ease-out duration-200`}
            >
                {icon}
            </a>
        </li>
    );
};

export default Card;

