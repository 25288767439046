import React, { useContext } from "react";
import {ThemeContext} from "../../../../ThemeProvider/ThemeProvider";

const SkillsItem = (props) => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";

    const {icon, title, description} = props.service;
    return (
        <div className="w-full lg:w-1/2 mb-8">
            <div className={`${isDarkMode ? "bg-gray-900" : "bg-white"} md:mx-4 p-6 shadow rounded-md group hover:shadow-md overflow-hidden flex flex-col h-full`}>
                <div className={`w-16 h-16 flex items-center justify-center rounded-md text-3xl mb-5 ${isDarkMode ? "bg-transparent" : "bg-blue-50"} text-blue-500 transition duration-200 group-hover:bg-blue-500 group-hover:text-white`}>
                    {icon}
                </div>
                <div className="flex-grow">
                <h3 className={`text-lg font-medium ${boldColor} mb-2`}>{title}</h3>
                <p className="text-gray-400">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default SkillsItem;