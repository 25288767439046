import React, { useContext } from "react";
import {ThemeContext} from "../../../../ThemeProvider/ThemeProvider";

const SoftSkillsItems = (props) => {
    const { isDarkMode } = useContext(ThemeContext);

    const textColor = isDarkMode ? "text-gray-300" : "text-gray-500"

    const { title } = props.softskills;
    return (
        <div className="w-full md:w-1/3 flex flex-col">
            <div className={`mb-2 md:mx-4 shadow rounded-md ${isDarkMode ? "bg-gray-900" : "bg-yellow-50"} group hover:shadow-md`}>
                <div className={`inline-block w-full text-center py-2 px-6 ${textColor} font-medium`}>
                    {title}
                </div>
            </div>
        </div>
    );
};

export default SoftSkillsItems;