import React, { useContext } from "react";
import {FaCode, FaHtml5, FaNodeJs, FaReact} from "react-icons/fa";
import {
    SiTailwindcss,
    SiAdobephotoshop,
    SiAdobeillustrator,
    SiIntellijidea,
    SiBootstrap,
    SiYoutube, SiNextdotjs, SiMysql,
} from "react-icons/si";
import { AiOutlineLaptop } from "react-icons/ai";
import SkillsItem from "./SkillsItem";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";

const serviceData = [
    {
        id: 3,
        icon: <SiTailwindcss className="text-blue-400 group-hover:text-white transition-colors duration-300"/>,
        title: "Tailwind CSS",
        description:
            "Strong proficiency to create unique and mobile-first websites.",
    },
    {
        id: 4,
        icon: <SiBootstrap  className="text-purple-700 group-hover:text-white transition-colors duration-300"/>,
        title: "Bootstrap CSS",
        description:
            "Rapid prototyping for functional and wireframing designs.",
    },
    {
        id: 5,
        icon: <FaReact />,
        title: "React.js",
        description:
            "Proficient in building responsive sites with React.js.",
    },
    {
        id: 3,
        icon: <SiNextdotjs className="text-black group-hover:text-white transition-colors duration-300"/>,
        title: "Next.js",
        description: "Developing SEO-friendly and performant websites with Next.js.",
    },
    {
        id: 4,
        icon: <FaNodeJs className="text-green-400 group-hover:text-white transition-colors duration-300"/>,
        title: "Node.js",
        description: "Back-end programming with Node.js.",
    },
    {
        id: 4,
        icon: <SiMysql className="text-blue-700 group-hover:text-white transition-colors duration-300"/>,
        title: "MySQL",
        description: "Designing and maintaining big data with MySQL.",
    },
    {
        id: 7,
        icon: <SiAdobeillustrator className="text-yellow-900 group-hover:text-white transition-colors duration-300"/>,
        title: "Adobe Illustrator",
        description:
            "Skilled in creating vector art logos and YouTube banners for content creators.",
    },
    {
        id: 8,
        icon: <SiAdobephotoshop />,
        title: "Adobe Photoshop",
        description:
            "7+ years of digital art experience creating thumbnails and game icons.",
    },
    {
        id: 10,
        icon: <SiYoutube className="text-red-500 group-hover:text-white transition-colors duration-300"/>,
        title: "Vegas Pro and Adobe Premiere Pro",
        description:
            "8+ years of video production experience with Vegas Pro and Adobe Premiere Pro for YouTube videos.",
    },
    {
        id: 11,
        icon: <AiOutlineLaptop />,
        title: "Content Creation",
        description:
            "8+ years of experience creating kid-friendly, trending content for YouTube and Roblox.",
    },
];

const Skills = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const boldColor = isDarkMode ? "text-gray-100" : "text-gray-800";

    return (
        <section className="pb-4">
            <div className="flex justify-center flex-wrap">
                <div className="w-full">
                    <div className="md:mx-4">
                        <h3 className={`text-2xl font-medium mb-4 ${boldColor}`}>Services I Provide</h3>
                    </div>
                </div>
                {serviceData.map((service) => (
                    <SkillsItem service={service} key={service.id} />
                ))}
            </div>
        </section>
    );
};

export default Skills;
